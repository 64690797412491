<template>
    <div>
      <swiper class="swiper" :options="tugeen_delgervvleh_st">
        <!--Company news slide-->
        <swiper-slide  v-for="(brigad, indexBrigad) in ds_brigad_view" :key="indexBrigad">
          <div class="slide_item">
            <div class="p_slide_header">
              <div class="company_img">
                <img :src="IMAGE_URL + brigad.logo" alt="" v-if="brigad.logo != ''">
                <img src="../../assets/no-image.png" alt="" v-else>
              </div>
              <div class="company_name">
                <router-link :to="`/brgd_tanilts/${brigad.id}`"><p>{{ brigad.ner }}</p>
                </router-link>
              </div>
            </div>
            <div class="slide_item_1_1">
              <ul>
                <li v-show="subIndex <= 2" :key="subIndex" v-for="(b_zar, subIndex) in brigad.ds_brigad_ajilin_bair_zar_view">
                  {{ b_zar.ajiliin_torol }}
                </li>
              </ul>
            </div>
            <!--                  <div class="slide_item_1_2">-->
            <!--                    <span>Ажлын байрны зар: 5</span>-->
            <!--                  </div>-->
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
</template>

<script>

import {GET_BRIGAD_VIEW_LIMIT_10, IMAGE_URL} from "../../graphql/queries";
export default {
   name: "brigad_slide",
  data () {
    return {
      IMAGE_URL: IMAGE_URL,
      ds_brigad_view: [],
      tugeen_delgervvleh_st: {
        slidesPerView: 4,
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          414: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          375: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 15
          }
        }
      }
    }
  },
  mounted() {
    this.getBrigad();
  },
  methods: {
    getBrigad() {
      this.$apollo.query({query: GET_BRIGAD_VIEW_LIMIT_10}).then(({data}) => {
        this.ds_brigad_view = data.ds_brilgas_view;
      })
    },
  },
}
</script>

<style scoped>

</style>